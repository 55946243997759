import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function EducationPage() {
  return (
    <Layout pageName="industry">
      <SEO
        title="Technology Solutions for Schools, Colleges, and other Education Institutes"
        description="WYN Technologies offers business technology solutions to Schools and universities across the USA. Contact us to get a quote for interactive whiteboards, camera surveillance, and router/firewall technologies."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Education</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for Educators and Educational Institutions</GroceryTitle>
              <p>There’s no doubt that technology has played an increasing role in modern education, and will continue to do so as time keeps marching forward. As technologies continue to advance, there are more applications being developed all the time towards the goal of benefiting students of all ages, and at all stages of the learning process. The right hardware and software combinations can be used to supplement standard classroom curriculum in some powerful ways. Interactive technologies have also been shown to be more engaging for students, and make the learning process easier to facilitate for educators, as well.</p>
              <p>Looking for some good business technology solutions to benefit your school or university? Here at <strong>WYN Technologies</strong>, we love partnering with all sorts of schools, educational institutions, and learning centers to provide many valuable technology installations. Applications like interactive whiteboards (a.k.a. “smart” boards) can really elevate the learning experience for students, and we offer many other essential technologies to benefit both educators and administrators alike. Keep reading to learn more about what we have to offer!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">Some of the Services We Offer for Schools, Universities, and Educational Facilities</GroceryIndustryTitle>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Digital Signage Installation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>More & more schools and universities are choosing to take advantage of digital signage, and for a good reason. Outdoor digital signs are a great way to inform parents and students about upcoming events, or to make important announcements. Outdoor signs used correctly also do a good job of drawing more attention to your school in a positive manner. Indoor digital signage is a powerful tool as well. It can help direct students about where they need to go when, and can also be utilized to help communicate general school announcements in a more engaging manner than with static, printed signs. And here’s even more good news – <strong>WYN</strong> can install whatever digital signage you may need!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Interactive Whiteboard Installations</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Interactive whiteboards – more commonly known as “smart” boards within the educational community – have been a common staple of hands-on, intuitive learning in classrooms across America for many years. Even so, interactive whiteboard technology has continued to grow and evolve, and currently-available products have come a long way from the first iterations to enter the market way back in the early 1990s. To that end, <strong>WYN</strong> is happy to provide and install the best interactive whiteboard installation solutions for local schools, colleges, universities, and educational institutions. Like to learn more about what modern interactive whiteboards can do for you? Get in touch with <strong>WYN Technologies</strong> today for your free demo!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Data Cabling, including Fiber</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Most every American school has some type of internet/data network in place, but some of those networks have now become pretty dated. Fiber optic networks are now being implemented in more and more businesses and schools, for a good reason. Fiber optic cabling allows for higher data transmission speeds with essentially zero interference. Fiber optics greatly increases your effective bandwidth too, meaning you can easily incorporate video streaming into classrooms for teachers, surveillance & security systems for administrators, and still keep all of your student devices connected at reliably fast speeds. Contact <strong>WYN</strong> to learn more.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">WiFi Networks & Access Points</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>WiFi access points have become a vital necessity for modern education. Students, teachers, professors, and administrators alike need access to a reliable WiFi network in order to take full advantage of learning and instructional computers and devices. How’s the WiFi network at your school? Got some dead spots? Need some new access points installed? <strong>WYN Technologies</strong> can troubleshoot your existing system, and also provide any installation solutions you may need.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
<GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">VoIP Phone Systems</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Every school, college, and learning center needs reliable phone and communication services. Older legacy systems don’t offer much in the way of expanded functionality, and can be more expensive to operate & maintain than they’re worth. That’s why it makes a lot of sense to upgrade to a VoIP-based phone system. It affords many advanced features you simply can’t get otherwise, and VoIP can save you some money, too. Reach out to the pros at <strong>WYN</strong>, and let us deliver a better communications solution for you!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Body Temperature Remote Monitoring Stations</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Thanks to the impacts of the COVID-19 pandemic, we’ve all become even more aware about the importance of maintaining good social health safety practices. And one good way for schools and educational institutions to help manage that is through remote body temperature monitoring technology. <strong>WYN</strong> has partnered with many businesses and organizations to install touchless thermal camera systems that are both incredibly effective and essentially effortless to use, and we’d be glad to help meet your remote temperature monitoring needs too.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              <MarkdownContentTitle>
                 {/* <h2 className="h1">Content Heading</h2> */}
              </MarkdownContentTitle>
              <MarkdownContent>
                <h2>Access Control Technology for Your Education Campus</h2>
                <p>In order to meet local fire safety regulations, schools and other educational facilities are required to maintain many emergency exits. The challenge that ensues is figuring out how to limit school access from the outside. Access control systems are beneficial in addressing many potential risks, and make for a more secure environment. Access control can also be used to determine who can gain access to certain rooms and facilities on the inside, as well. Thanks to modern technological advances, there are many effective keyless door options available, including PIN pads, key fobs, mobile device activation, and even biometrics. Looking for a good local provider and installer of custom access control systems? You’ve found one in <strong>WYN Technologies</strong>!</p>
               </MarkdownContent>
              <MarkdownContent>
                <h2>Choose WYN Technologies for Your Educational Technology Solutions</h2>
                <ul>
                  <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At WYN, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility, before we ever come out to install your new equipment.</p>
                  </li>
                  <li>
                    <strong>Only the Highest-Quality Products & Services</strong>
                    <p>WYN Technologies only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                  </li>
                  <li>
                    <strong>We Know How to Work Within Your Budget</strong>
                    <p>WYN is ultimately here to help your business succeed. We understand corporate budgets & finances, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                  </li>
                  <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, WYN is committed to be here for you for the long run. We actually answer the phone when you call, and we’ll respond promptly to your needs 24/7.</p>
                  </li>
                </ul>
              </MarkdownContent>
                 <MarkdownContent>
                <h2>Got Questions? Ready to Learn More? Let’s Start the Conversation!</h2>
                <p>If you’re considering making some technology upgrades for your educational facility, or even for multiple locations or campuses, <strong>WYN Technologies</strong> is happy to provide an initial site evaluation and consultation at no cost. Connect with us today at <a href="tel:3368990555">(336) 899-0555</a>, and let us help you achieve your best technology solutions!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default EducationPage
